import { ITEM_SIZE } from '#/components/Item/itemHelper';
import { DETAIL_ROUTES } from '#/config/constants';
import getResolution from '#/utils/getResolution';

import { getParameterizedImage } from '../utils';

/**
 * @module models/OVP
 */

/**
 * Amount of stars to display as credits
 * @type {Number}
 */
const CREDITS_LENGTH = 5;

/**
 * Item's size.
 * It's used to get the image's template Url with the correct size
 * @type {String}
 */
const ITEM_TYPE_PORTRAIT_SIZE = ITEM_SIZE.portrait;
const ITEM_TYPE_LANDSCAPE_SIZE = ITEM_SIZE.landscape;
const ITEM_TYPE_SQUEARE_SIZE = ITEM_SIZE.square;

/**
 * This model represents the Show Object
 *
 * @typedef {Object} Show
 * @property {String} id Id of the Show
 * @property {String} category Show Category
 * @property {Array<Credit>} credits Show Credits
 * @property {String} description Show description
 * @property {String} title Show Title
 * @property {String} link Route to use in the application to navigate to its detail
 * @property {Array<Image>} images Array of the images of the Show
 * @property {String} coverUrl Show Cover Image URL
 * @property {String} fullWithCoverUrl Image url for the cover image of the Show
 * @property {String} type Type of content: show
 * @property {Number} seasonCount Number of seasons
 * @property {String} metadata Metadata to show on the detail page
 *
 */

/**
 * Show Model
 * @param {Object} rawShow Show
 * @returns {Show} show - The {@link Show} representation
 */
const Show = rawShow => {
  const {
    categories,
    credits: rawCredits,
    description,
    id,
    title,
    images,
    tvSeasonCount,
    publishedDate
  } = rawShow;

  /**
   * Full image size
   */
  const { width: imageFullWidth, height: imageFullHeight } = getResolution();

  /**
   * Get the Shows's id
   * @returns {String} ID
   */
  const getDescription = () => {
    return description;
  };

  /**
   * Get the Shows's id
   * @returns {String} ID
   */
  const getId = () => {
    return id;
  };

  /**
   * Get the Episode's Show Primary Category
   * @returns {String} Show Category
   */
  const getShowCategoryId = () => {
    if (categories.length === 0) {
      return null;
    }
    const [category] = categories;
    return category.id;
  };

  /**
   * Get the Episode's categories
   * @returns {String} Categories
   */
  const getCategories = () => {
    if (categories) {
      return `${categories.map(category => category.title).join(', ')}`;
    }
    return '';
  };

  /**
   * Get the Shows's title
   * @returns {String} Title
   */
  const getTitle = () => {
    return title;
  };

  /**
   * Get the image resolution depending on the image type
   * @param {String} imageType getImages
   * @returns {Object} image size
   */
  const getImageTemplateUrlSize = imageType => {
    let imageSize = {};

    if (imageType === 'poster' || imageType === 'poster_clean') {
      imageSize = ITEM_TYPE_PORTRAIT_SIZE;
    } else if (imageType === 'backdrop_clean') {
      imageSize = {
        height: imageFullHeight,
        width: imageFullWidth
      };
    } else if (imageType === 'backdrop') {
      imageSize = ITEM_TYPE_LANDSCAPE_SIZE;
    } else if (imageType === 'logo') {
      imageSize = ITEM_TYPE_SQUEARE_SIZE;
    }

    return imageSize;
  };

  /**
   * Get the Shows's images
   * @returns {Array} Images url
   */
  const getImages = () => {
    return images.map(img => {
      // Doing the image's template URL transform
      const image = getImageTemplateUrlSize(img.type);

      img.url = getParameterizedImage({
        imgUrl: img.templateUrl,
        imgWidth: image.width,
        width: img.width
      });

      return img;
    });
  };

  /**
   * Get the Shows's link
   * @return {String} Link
   */
  const getLink = () => {
    const path = DETAIL_ROUTES.tvshow;
    const link = path.replace(':item-id:', id);
    return link;
  };

  /**
   * Get the Shows's season count
   * @return {String} tvSeasonCount
   */
  const getTvSeasonCount = () => {
    return tvSeasonCount;
  };

  const getYear = () => {
    if (publishedDate) {
      const date = new Date();
      date.setTime(publishedDate);
      return date.getFullYear();
    }
  };

  /**
   * Get the Episode's metadata
   * @returns {String} Metadata
   */
  const getMetadata = () => {
    const metadata = `${getYear()} | ${getTvSeasonCount()} Seasons | ${getCategories()}`;
    return metadata;
  };

  /**
   * Get the Episode's credits
   * @returns {Array<Object>} Credits
   */
  const getCredits = () => {
    if (rawCredits) {
      const [director = [], ...actors] = rawCredits.slice(0, CREDITS_LENGTH);
      const credits = [];
      credits.push({ role: 'Director', names: [director.name] });
      credits.push({
        role: 'Cast',
        names: actors.map((actor = []) => actor.name)
      });
      return credits;
    }
    return [];
  };

  /**
   * Get the first Episode's cover url
   * @returns {String} Cover URL
   */
  const getCoverUrl = ({ width, type = 'poster' } = {}) => {
    if (images) {
      let filteredImages = images.filter(image => image.type === type);
      if (filteredImages.length === 0) {
        filteredImages = images;
      }

      const coverImage = filteredImages[0];

      return getParameterizedImage({
        imgUrl: coverImage.templateUrl,
        // Detail view background image size
        imgWidth: getImageTemplateUrlSize(coverImage.type).width,
        width: width || coverImage.width
      });
    }
  };

  return {
    category: getShowCategoryId(),
    credits: getCredits(),
    description: getDescription(),
    id: getId(),
    title: getTitle(),
    link: getLink(),
    images: getImages(),
    coverUrl: getCoverUrl(),
    backgroundUrl: getCoverUrl({
      type: 'backdrop_clean'
    }),
    fullWithCoverUrl: getCoverUrl({
      width: imageFullWidth
    }),
    type: 'tvshow',
    seasonCount: getTvSeasonCount(),
    metadata: getMetadata()
  };
};

export default Show;
