import { ITEM_SIZE } from '#/components/Item/itemHelper';
import { getSeason } from '#/services/ovp/season';
import defaultCoverImage from '#/static/images/show_placeholder.png';
import { getParameterizedImage } from '../utils';

/**
 * Amount of stars to display as credits
 * @type {Number}
 */
const CREDITS_LENGTH = 5;

/**
 * Item's size.
 * It's used to get the image's template Url with the correct size
 * @type {String}
 */
const ITEM_TYPE_SEASON_EPISODE = ITEM_SIZE['season-episode'];

/**
 * @module models/OVP
 */

/**
 * @typedef {Object} Episode
 * @property {String} id Episode ID
 * @property {Number} episodeNumber Episode number
 * @property {String} showId Episode Show ID
 * @property {String} showTitle Episode Show Title
 * @property {String} category Episode Show Category
 * @property {String} seasonId Episode Season ID
 * @property {Number} seasonNumber Episode Season Number
 * @property {String} coverUrl Episode Cover Image URL
 * @property {String} backgroundUrl Episode Background Image URL
 * @property {String} metadata Episode Metadata
 * @property {String} title Episode Full Tile
 * @property {String} episodeTitle Episode Simple Tile
 * @property {String} description Episode Description
 * @property {Array<Credit>} credits Episode Credits
 * @property {String} videoUrl Episode Video URL
 * @property {String} type Episode Type
 * @property {String} tvShowDescription Description of the associated TVShow
 * @property {String} publishedDate Publication date
 * @property {String} categories List of categories separated by comma
 * @property {String} duration duration of the episode as string in minutes
 *
 */

/**
 * Episode Model
 * @param {Object} rawEpisode Episode
 * @param {string} [segmentationValue] segment to ask content from
 * @returns {Episode} Episode
 */
const Episode = async (rawEpisode, segmentationValue) => {
  /**
   * Get the Episode's id
   * @returns {String} ID
   */
  const getId = () => {
    const { id } = rawEpisode;
    return id;
  };

  /**
   * Get the Episode Number
   * @returns {Number} Episode Number
   */
  const getEpisodeNumber = () => {
    const { metadata } = rawEpisode;
    const episodeNumber = metadata.find(
      item => item.name === 'VOD$episodeNumber'
    );
    return episodeNumber.value;
  };

  /**
   * Get the Episode's Show id
   * @returns {String} Show ID
   */
  const getShowId = () => {
    const { metadata } = rawEpisode;
    const showId = metadata.find(item => item.name === 'VOD$tvShowId');
    return showId.value;
  };

  /**
   * Get the Episode's Show title
   * @returns {String} Show Title
   */
  const getShowTitle = () => {
    const { metadata } = rawEpisode;
    const showTitle = metadata.find(item => item.name === 'VOD$tvShowTitle');
    return showTitle.value;
  };

  /**
   * Get the Episode's Show Primary Category
   * @returns {String} Show Category
   */
  const getShowCategoryId = () => {
    const { categories = [] } = rawEpisode;
    if (categories.length === 0) {
      return null;
    }
    const [category] = categories;
    return category.id;
  };

  /**
   * Get the Episode's Season id
   * @returns {String} Season ID
   */
  const getSeasonId = () => {
    const { metadata } = rawEpisode;
    const seasonId = metadata.find(item => item.name === 'VOD$tvSeasonId');
    return seasonId.value;
  };

  /**
   * Get the Episode's Season number
   * @returns {Promise<Number>} ID
   */
  const getSeasonNumber = async () => {
    const { metadata } = rawEpisode;
    const tvShowId = metadata.find(item => item.name === 'VOD$tvShowId'); // {"value": "1668"}
    const seasonId = metadata.find(item => item.name === 'VOD$tvSeasonId'); // {"value": "52570fcd19c295731c020203"}
    // a request by Episode
    const tvSeason = await getSeason(
      seasonId.value,
      tvShowId.value,
      segmentationValue
    );
    return Number(tvSeason?.tvSeasonNumber);
  };

  /**
   * Get the first Episode's cover url
   * @returns {String} Cover URL
   */
  const getCoverUrl = () => {
    const { images } = rawEpisode;

    if (images) {
      const [coverImage] = images;
      return getParameterizedImage({
        imgUrl: coverImage?.templateUrl,
        imgWidth: ITEM_TYPE_SEASON_EPISODE?.width,
        width: coverImage?.width
      });
    }
    return defaultCoverImage;
  };

  /**
   * Get the first Episode's cover url
   * @returns {String} Cover URL
   */
  const getBackgroundUrl = () => {
    return getCoverUrl();
  };

  /**
   * Get the publication date
   * @returns {String} Publication date
   */
  const getPublishedDate = () => {
    const { publishedDate } = rawEpisode;
    return publishedDate;
  };

  /**
   * Get the Episode's year production
   * @returns {Number} Year Production
   */
  const getYear = () => {
    const publishedDate = getPublishedDate();
    const date = new Date();
    date.setTime(publishedDate);
    return date.getFullYear();
  };

  /**
   * Get the Episode's categories
   * @returns {String} Categories
   */
  const getCategories = () => {
    const { categories } = rawEpisode;
    if (categories) {
      return categories.map(category => category.title).join(', ');
    }
    return '';
  };

  /**
   * Get the Episode's seasons count
   * @returns {String} Episode Seasons Count
   */
  const getSeasonsCount = () => {
    const { tvSeasonCount } = rawEpisode;
    if (tvSeasonCount) {
      return `${tvSeasonCount} Seasons`;
    }
    return '';
  };

  /**
   * Get the Episode's duration
   * @returns {String} Duration
   */
  const getDuration = () => {
    const { contents } = rawEpisode;
    const { duration } = contents[0];
    return `${Math.floor(duration / 1000 / 60)} mins`;
  };

  /**
   * Get the Episode's metadata
   * @returns {String} Metadata
   */
  const getMetadata = () => {
    const metadata = `${getYear()} | ${getSeasonsCount()} | ${getCategories()}`;
    return metadata;
  };

  /**
   * Get the Episode's title
   * @returns {String} Title
   */
  const getTitle = () => {
    const { title } = rawEpisode;
    return title ? `${getEpisodeNumber()}. ${title}` : '';
  };

  /**
   * Get the Episode's title
   * @returns {String} Title
   */
  const getEpisodeTitle = () => {
    const { title } = rawEpisode;
    return title;
  };

  /**
   * Get the Episode's description
   * @returns {String} Description
   */
  const getDescription = () => {
    const { description } = rawEpisode;
    return description;
  };

  /**
   * Get the Show's description
   * @returns {String} Description
   */
  const getTvShowDescription = () => {
    const { tvShowDescription } = rawEpisode;
    return tvShowDescription;
  };

  /**
   * Get the Episode's credits
   * @returns {Array<Object>} Credits
   */
  const getCredits = () => {
    const { credits: rawCredits } = rawEpisode;
    if (rawCredits) {
      const [director = [], ...actors] = rawCredits.slice(0, CREDITS_LENGTH);
      const credits = [];
      credits.push({ role: 'Director', names: [director.name] });
      credits.push({
        role: 'Cast',
        names: actors.map((actor = []) => actor.name)
      });
      return credits;
    }
    return [];
  };

  /**
   * Get the Episode's video url. Hardcoded to our hosted video
   * @returns {String} Video URL
   */
  const getVideoUrl = () => {
    // other possible value with subs/multiaudio: '//d1knl4odus8cue.cloudfront.net/bbb/bbb_sunflower_1080p_60fps_normal.mp4'
    return '//d3qnhznroa8hr5.cloudfront.net/videos/bbb_multiaudio.mp4'; // multiaudio
  };

  return {
    id: getId(),
    episodeNumber: getEpisodeNumber(),
    showId: getShowId(),
    showTitle: getShowTitle(),
    category: getShowCategoryId(),
    seasonId: getSeasonId(),
    seasonNumber: await getSeasonNumber(),
    coverUrl: getCoverUrl(),
    backgroundUrl: getBackgroundUrl(),
    metadata: getMetadata(),
    title: getTitle(),
    episodeTitle: getEpisodeTitle(),
    description: getDescription(),
    tvShowDescription: getTvShowDescription(),
    credits: getCredits(),
    videoUrl: getVideoUrl(),
    type: 'episode',
    publishedDate: getPublishedDate(),
    categories: getCategories(),
    duration: getDuration()
  };
};

export default Episode;
