const navIdMap = {
  TEST_PAGE: 'TEST_PAGE',
  EPG_PAGE: 'EPG_PAGE',
  MENU: {
    HEADER: {
      CONTAINER: 'HEADER_CONTAINER',
      MENU: 'HEADER_MENU',
      MENU_ITEM: 'MENU_',
      USER_CONTROL: 'USER_CONTROL',
      SEARCH_BUTTON: 'SEARCH_BUTTON',
      SETTING_BUTTON: 'SETTING_BUTTON',
      MY_CONTENT_BUTTON: 'MENU_primary-item-my-content'
    },
    FOOTER: {
      MENU: 'FOOTER_MENU'
    }
  },
  PAGE: {
    ABOUT: {
      ACTION_BUTTON: 'ACTION_BUTTON',
      CONTAINER: 'CONTAINER',
      PAGE: 'PAGE'
    },
    CONTAINER: {
      PAGE: 'CONTAINER_PAGE',
      LAYOUT: 'CONTAINER_LAYOUT'
    },
    DETAIL: {
      ASSET_ACTIONS: {
        CONTAINER: 'ACTIONS_CONTAINER',
        PLAY_BUTTON: 'DETAIL_PLAY_BUTTON',
        LOGIN_BUTTON: 'DETAIL_LOGIN_BUTTON',
        SUBSCRIBE_BUTTON: 'DETAIL_SUBSCRIBE_BUTTON',
        FAVOURITE_BUTTON: 'DETAIL_FAVOURITE_BUTTON',
        RESUME_BUTTON: 'DETAIL_RESUME_BUTTON',
        ORDER_BUTTON: 'ORDER_BUTTON',
        DOWNLOAD_BUTTON: 'DOWNLOAD_BUTTON',
        CTA: 'CTA',
        REMINDER_BUTTON: 'REMINDER_BUTTON',
        MORE_OPTIONS: 'MORE_OPTIONS',
        MORE_OPTIONS_LIST: 'MORE_OPTIONS_LIST'
      }
    },
    SHOWS: {
      PAGE: 'SHOWS_PAGE',
      SHOWS_CAROUSEL_CONTAINER: 'SHOWS_CAROUSEL_CONTAINER',
      SHOWS_CAROUSEL: 'SHOWS_CAROUSEL'
    },
    FAVOURITES: {
      PAGE: 'FAVOURITES_PAGE',
      CAROUSEL_ENTRY: 'FAVOURITES_CAROUSEL_ENTRY',
      CONTAINER: 'FAVOURITES_CONTAINER'
    },
    EPISODE_DETAILS: {
      PAGE: 'EPISODE_DETAILS_PAGE',
      INFO: 'EPISODE_DETAILS_INFO',
      SEASON_EPISODES: 'EPISODE_DETAILS_SEASON_EPISODES',
      EPISODES_CAROUSEL_CONTAINER:
        'EPISODE_DETAILS_EPISODES_CAROUSEL_CONTAINER',
      EPISODES_CAROUSEL: 'EPISODE_DETAILS_EPISODES_CAROUSEL',
      SEASONS_BUTTON: 'EPISODE_DETAILS_SEASONS_BUTTON',
      SEASON_LIST: 'EPISODE_DETAILS_SEASON_LIST',
      CAROUSEL: 'EPISODE_DETAILS_CAROUSEL',
      EPISODE_DETAIL_POPUP_PAGE: 'EPISODE_DETAIL_POPUP_PAGE',
      EPISODE_DETAIL_POPUP: 'EPISODE_DETAIL_POPUP',
      ERROR_SEASON_POPUP: 'ERROR_SEASON_POPUP'
    },
    MOVIE_DETAILS: {
      PAGE: 'MOVIE_DETAILS_PAGE',
      INFO: 'MOVIE_DETAILS_CONTAINER',
      MOVIES_CAROUSEL_CONTAINER: 'MOVIE_DETAILS_MOVIES_CAROUSEL_CONTAINER',
      CAROUSEL: 'MOVIE_DETAILS_CAROUSEL',
      MOVIE_DETAIL_POPUP_PAGE: 'MOVIE_DETAIL_POPUP_PAGE',
      MOVIE_DETAIL_POPUP: 'MOVIE_DETAIL_POPUP',
      MOVIE_RESUME_POPUP: 'MOVIE_RESUME_POPUP'
    },
    PROGRAM_DETAILS: {
      PAGE: 'PROGRAM_DETAILS_PAGE',
      CAROUSEL: 'PROGRAM_DETAILS_CAROUSEL'
    },
    LIST: {
      PAGE: 'LIST_PAGE',
      LIST_GRID_CONTAINER: 'LIST_GRID_CONTAINER',
      LIST_GRID: 'LIST_GRID',
      FILTER_LIST: 'FILTER_LIST',
      FILTER_LIST_WRAPPER: 'FILTER_LIST_WRAPPER',
      FILTER_BUTTON: 'FILTER_BUTTON',
      SORT_BUTTON: 'SORT_BUTTON',
      SORT_LIST: 'SORT_LIST',
      SORT_LIST_WRAPPER: 'SORT_LIST_WRAPPER',
      SELECTOR_LIST: 'SELECTOR_LIST',
      SELECTOR_LIST_WRAPPER: 'SELECTOR_LIST_WRAPPER',
      SELECTOR_BUTTON: 'SELECTOR_BUTTON'
    },
    LIVE: {
      PAGE: 'LIVE_PAGE',
      MINIGUIDE_CONTAINER: 'MINI_GUIDE',
      BTN_CONTAINER: 'BTN_CONTAINER',
      BTN_WATCHING: 'WATCHING_BUTTON',
      BTN_WATCH_NOW: 'WATCH_NOW_BUTTON',
      BTN_FULL_GUIDE: 'FULL_GUIDE_BUTTON',
      ENTITLEMENT_POPUP: 'MINI_GUIDE_ENTITLEMENT_POPUP',
      ARROW_UP: 'MINI_GUIDE_ARROW_UP',
      ARROW_DOWN: 'MINI_GUIDE_ARROW_DOWN'
    },
    SEARCH: {
      PAGE: 'SEARCH_PAGE',
      CONTAINER: 'SEARCH_CONTAINER',
      KEYBOARD: 'SEARCH_KEYBOARD',
      KEYBOARD_CONTAINER: 'SEARCH_KEYBOARD_CONTAINER',
      SIDE_CONTAINER: 'SEARCH_SIDE_CONTAINER',
      TRENDS_CAROUSEL: 'SEARCH_TRENDS_CAROUSEL',
      RESULTS_MOVIES: 'SEARCH_RESULTS_MOVIES',
      RESULTS_SHOWS: 'SEARCH_RESULTS_SHOWS'
    },
    PROFILE: {
      PAGE: 'PROFILE_PAGE',
      CONTAINER: 'PROFILE_CONTAINER',
      INFODIV: 'INFO_PANEL',
      MY_ELEVATE: 'MY_ELEVATE_TAB',
      MANAGE_PROFILES: 'PROFILE_TAB',
      LOGOUT: 'LOGOUT_TAB',
      LOGOUT_POPUP: 'LOGOUT_POPUP'
    },
    INFO: {
      PAGE: 'INFO_PAGE',
      CONTAINER: 'INFO_CONTAINER',
      ABOUT: 'ABOUT',
      TERMS: 'TERMS',
      HELP: 'HELP',
      CONTACT: 'CONTACT',
      LANGUAGE: 'LANGUAGE',
      LANGUAGE_GRID: 'LANGUAGE_GRID',
      SCROLLABLEDIV: 'DIV--TEXT',
      APP_VERSION: 'APP_VERSION'
    },
    EPG: {
      PAGE: 'EPG_PAGE',
      PROGRAM_GUIDE: 'PROGRAM_GUIDE',
      LOGIN_POPUP: 'EPG_LOGIN_POPUP',
      ENTITLEMENT_POPUP: 'EPG_ENTITLEMENT_POPUP',
      PROGRAM_PREVIEW: 'EPG_PROGRAM_PREVIEW',
      DAY_SELECTOR: 'DAY_SELECTOR'
    },
    PLAYER: {
      PAGE: 'PLAYER_PAGE',
      CONTROL: 'PLAYER_CONTROL',
      PLAY_PAUSE: 'PLAYER_PLAY_PAUSE',
      RW: 'PLAYER_RW',
      FF: 'PLAYER_FF',
      PREV: 'PLAYER_PREV',
      NEXT: 'PLAYER_NEXT',
      CC: 'PLAYER_CC',
      AUDIO: 'PLAYER_AUDIO',
      RESUME_POPUP: 'PLAYER_RESUME_POPUP',
      ERROR_POPUP: 'ERROR_POPUP',
      BACK: 'PLAYER_BACK'
    },
    CATEGORY: {
      PAGE: 'CATEGORY_PAGE'
    },
    PIN: {
      PAGE: 'PIN_PAGE',
      CONTAINER: 'PIN_CONTAINER',
      KEYBOARD: 'PIN_KEYBOARD',
      CANCEL: 'PIN_CANCEL',
      PROCEED: 'PIN_PROCEED',
      OPTIONS: 'PIN_OPTIONS'
    },
    VIEWALL: {
      PAGE: 'VIEW_ALL'
    }
  },
  PLAYER: {
    CONTROL: 'PLAYER_CONTROL',
    PLAY_PAUSE: 'PLAYER_PLAY_PAUSE',
    RW: 'PLAYER_RW',
    FF: 'PLAYER_FF',
    PREV: 'PLAYER_PREV',
    NEXT: 'PLAYER_NEXT',
    CC: 'PLAYER_CC',
    RESUME_POPUP: 'PLAYER_RESUME_POPUP'
  },
  POPUP: {
    CANCEL_POPUP: 'CANCEL_POPUP',
    OK_POPUP: 'OK_POPUP',
    GLOBAL_ERROR_POPUP_PAGE: 'GLOBAL_ERROR_POPUP_PAGE',
    GLOBAL_ERROR_POPUP: 'GLOBAL_ERROR_POPUP'
  },
  LOGIN: {
    PAGE: 'LOGIN_PAGE',
    CONTAINER: 'KEYBOARD_CONTAINER',
    KEYBOARD: 'KEYBOARD',
    USERNAME: 'USERNAME_BUTTON',
    PASSWORD: 'PASSWORD_BUTTON',
    LOGIN: 'LOGIN_BUTTON',
    LOGINMENU: 'LOGIN_MENU_BUTTON',
    PAIRCODE: 'PAIRCODE_BUTTON',
    FORGOT: 'FORGOT_BUTTON',
    CANCEL: 'CANCEL_BUTTON',
    CLOSEPOPUP: 'CLOSE_BUTTON'
  },
  PROFILE_SELECTION: {
    PAGE: 'PROFILE_SELECTION',
    CONTAINER: 'PROFILE_SELECTION_CONTAINER',
    ADD_NEW_PROFILE: 'ADD_NEW_PROFILE',
    PROFILE_CAROUSEL: 'PROFILE_SELECTION_PROFILE_CAROUSEL'
  },
  MANAGE_PROFILE: {
    PAGE: 'MANAGE_PROFILE_PAGE',
    CONTAINER: 'MANAGE_PROFILE_CONTAINER',
    BACK_BUTTON: 'MANAGE_PROFILE_BACK_BUTTON',
    CONTENT_CONTAINER: 'MANAGE_PROFILE_CONTENT_CONTAINER',
    SUB_MENU: 'MANAGE_PROFILE_SUB_MENU',
    PROFILE_PIN: {
      CONTAINER: 'MANAGE_PROFILE_PROFILE_PIN_CONTAINER',
      SETUP_BUTTON: 'MANAGE_PROFILE_PROFILE_PIN_SETUP_BUTTON',
      CHANGE_BUTTON: 'MANAGE_PROFILE_PROFILE_PIN_CHANGE_BUTTON',
      DELETE_BUTTON: 'MANAGE_PROFILE_PROFILE_PIN_DELETE_BUTTON'
    },
    PROFILE_INFO: {
      CONTAINER: 'MANAGE_PROFILE_PROFILE_INFO_CONTAINER',
      CHANGE_PROFILE_NAME_BUTTON:
        'MANAGE_PROFILE_PROFILE_INFO_CHANGE_PROFILE_NAME_BUTTON',
      CHANGE_PROFILE_AVATAR_BUTTON:
        'MANAGE_PROFILE_PROFILE_INFO_CHANGE_PROFILE_AVATAR_BUTTON'
    },
    DELETE_PROFILE: {
      CONTAINER: 'MANAGE_PROFILE_DELETE_PROFILE_CONTAINER',
      DELETE_BUTTON: 'MANAGE_PROFILE_DELETE_PROFILE_DELETE_BUTTON',
      DELETE_POPUP: 'MANAGE_PROFILE_DELETE_PROFILE_DELETE_POPUP'
    }
  },
  CREATE_PROFILE: {
    PAGE: 'CREATE_PROFILE_PAGE',
    CONTAINER: 'CREATE_PROFILE_CONTAINER',
    CHOOSE_YOUR_AVATAR: {
      OPTIONS: 'CREATE_PROFILE_CHOOSE_YOUR_AVATAR_OPTIONS',
      BACK_TO_PROFILES_BUTTON:
        'CREATE_PROFILE_CHOOSE_YOUR_AVATAR_BACK_TO_PROFILES_BUTTON',
      SKIP_FOR_NOW_BUTTON:
        'CREATE_PROFILE_CHOOSE_YOUR_AVATAR_SKIP_FOR_NOW_BUTTON'
    },
    ENTER_YOUR_NAME: {
      OPTIONS: 'CREATE_PROFILE_ENTER_YOUR_NAME_OPTIONS',
      BACK_BUTTON: 'CREATE_PROFILE_ENTER_YOUR_NAME_BACK_BUTTON',
      PROCEED_BUTTON: 'CREATE_PROFILE_ENTER_YOUR_NAME_PROCEED_BUTTON',
      NAME_INPUT: 'CREATE_PROFILE_ENTER_YOUR_NAME_PNAME_INPUT',
      KEYBOARD: 'CREATE_PROFILE_ENTER_YOUR_NAME_KEYBOARD'
    },
    IS_THIS_A_KID_PROFILE: {
      OPTIONS: 'CREATE_PROFILE_IS_THIS_A_KID_PROFILE_OPTIONS',
      BACK_BUTTON: 'CREATE_PROFILE_IS_THIS_A_KID_PROFILE_BACK_BUTTON',
      NO_ITS_NOT: 'CREATE_PROFILE_IS_THIS_A_KID_PROFILE_NO_ITS_NOT',
      YES_IT_IS: 'CREATE_PROFILE_IS_THIS_A_KID_PROFILE_NO_YES_IT_IS'
    }
  },
  CHANGE_PROFILE_AVATAR: {
    PAGE: 'CHANGE_PROFILE_AVATAR',
    CONTAINER: 'CHANGE_PROFILE_AVATAR_CONTAINER'
  },
  CHOOSE_YOUR_AVATAR_TEMPLATE: {
    GRID_CONTAINER: 'CHOOSE_YOUR_AVATAR_TEMPLATE_GRID_CONTAINER',
    GRID: 'CHOOSE_YOUR_AVATAR_TEMPLATE_AVATAR_GRID'
  },
  EDIT_NAME_TEMPLATE: {
    OPTIONS: 'EDIT_NAME_TEMPLATE_OPTIONS',
    BACK_BUTTON: 'EDIT_NAME_TEMPLATE_BACK_BUTTON',
    PROCEED_BUTTON: 'EDIT_NAME_TEMPLATE_PROCEED_BUTTON',
    NAME_INPUT: 'EDIT_NAME_TEMPLATE_NAME_INPUT',
    KEYBOARD: 'EDIT_NAME_TEMPLATE_KEYBOARD'
  },
  ID_INPUT: {
    PAGE: 'ID_INPUT_PAGE',
    CONTAINER: 'KEYBOARD_CONTAINER',
    KEYBOARD: 'KEYBOARD',
    APPLICATION_ID: 'APPLICATION_ID_BUTTON',
    GID: 'GID_BUTTON',
    ACTIVATE: 'ACTIVATE_BUTTON',
    CANCEL: 'CANCEL_BUTTON',
    CLOSEPOPUP: 'CLOSE_BUTTON'
  },
  PAIRINGCODE: {
    PAGE: 'PAIRINGCODE_PAGE',
    CONTAINER: 'PAIRINGCODE_CONTAINER',
    LOGINMENU: 'LOGIN_MENU_BUTTON',
    PAIRCODE: 'PAIRCODE_BUTTON'
  }
};

const getIds = containers => {
  const containerNavIds = [];
  containers.forEach(container => {
    if (container.containerNavIds) {
      container.containerNavIds.forEach(subContainerNavId =>
        containerNavIds.push(subContainerNavId)
      );
    } else {
      containerNavIds.push(container.id);
    }
  });
  return containerNavIds;
};

const getFirstAvailableContainer = (
  arrayWithContainers,
  arrayWithEmptyContainersIds
) => {
  const finalResult = [];
  for (
    let i = 0;
    i < arrayWithContainers.length;
    // eslint-disable-next-line no-plusplus
    i++
  ) {
    if (
      !arrayWithEmptyContainersIds?.includes(arrayWithContainers[i].id) &&
      !arrayWithContainers[i].skipNavigation
    ) {
      finalResult.push(arrayWithContainers[i]);
      break;
    }
  }

  return finalResult[0];
};

// This function compares the containers to display and define
// which container should be focused when they're rendered taking
// into consideration that some of them are empty.
//
// [TO REFACT]
// This logic is part of a fix that thas been implemented in
// the end of 2022 to remove empty containers. It should be
// reviewed and rethought.
//
// Some points of improvement has been identified:
// - Make it simplier and reduce the number of parameters and "ifs".
// - Reduce the number of calls triggered by the useEffect that calls it.
// - Remove business logics (ex: continue watching validation).
// - Turn it more readable and check if there are any logic that conflicts
//   with it (Compare with other logics at: ContainerPage, VerticalScroll
//   and Shelf components.
const getIdOfTheFirstContainerAvailable = (
  containers,
  emptyContainers,
  authContext,
  userBookmarks,
  continueWatching
) => {
  const isAuthenticated = Boolean(authContext.isAuthenticated);

  const hasUserBookmarksItems = Boolean(userBookmarks?.length);
  let firstElementFocused = {};
  let firstContainer;

  // [IMPORTANT] - ContinueWatching is not added to emptyContainers.
  // for more details: Check how it's added to "containers" at the
  // ContainerPage component.
  const containersWithoutContinueWatching = containers.filter(
    container => container?.template !== continueWatching
  );

  if (
    containersWithoutContinueWatching.length > emptyContainers.length &&
    !isAuthenticated
  ) {
    firstContainer = getFirstAvailableContainer(
      containersWithoutContinueWatching,
      emptyContainers
    );
    firstElementFocused = firstContainer;
  } else if (isAuthenticated && containers.length > emptyContainers.length) {
    if (hasUserBookmarksItems) {
      firstContainer = getFirstAvailableContainer(containers, emptyContainers);
      firstElementFocused = firstContainer;
    } else {
      firstContainer = getFirstAvailableContainer(
        containersWithoutContinueWatching,
        emptyContainers
      );
      firstElementFocused = firstContainer;
    }
  }
  // the first container has a different structure depend if is a detail view or not
  const firstElementFocusedID =
    firstElementFocused?.id || firstElementFocused?.containerNavIds?.[0];

  return firstElementFocusedID;
};

const NEXT_LEFT = 'nextleft';
const NEXT_UP = 'nextup';
const NEXT_RIGHT = 'nextright';
const NEXT_DOWN = 'nextdown';

const getPrevDirection = ({ vertical = false, rtl = false } = {}) => {
  let prevDirection = NEXT_LEFT;
  if (vertical) {
    prevDirection = NEXT_UP;
  } else if (rtl) {
    prevDirection = NEXT_RIGHT;
  }
  return prevDirection;
};

const getNextDirection = ({ vertical = false, rtl = false } = {}) => {
  let prevDirection = NEXT_RIGHT;
  if (vertical) {
    prevDirection = NEXT_DOWN;
  } else if (rtl) {
    prevDirection = NEXT_LEFT;
  }
  return prevDirection;
};

export {
  navIdMap,
  getIds,
  getIdOfTheFirstContainerAvailable,
  getPrevDirection,
  getNextDirection,
  NEXT_LEFT,
  NEXT_UP,
  NEXT_RIGHT,
  NEXT_DOWN
};
