import config from '#/config';

const {
  app: {
    image: {
      template: { IMAGE_QUALITY }
    },
    defaultScreenDimension: { width: MAX_VIEWPORT_SIZE }
  }
} = config;

const imageQuality =
  IMAGE_QUALITY > 0 && IMAGE_QUALITY <= 100 ? IMAGE_QUALITY : 5;

const getProxiedControlImage = (url, h, q = 80) =>
  `https://image-proxy.ps.accedo.tv/insecure/h:${h}/q:${q}/plain/https://cdn.one.accedo.tv/files/${url}`;

/**
 * Gets a image with a {template} substring and replace it with the correct values
 *
 * @param {Object} params Object with all the properties
 * @param {String} params.imgUrl Image URL. Expects to have a {template} substring
 * @param {Number} params.imgWidth Image width
 * @param {Number} params.width Element width
 * @returns {String} Url with interpolated parameters
 */
export const getParameterizedImage = ({
  imgUrl,
  imgWidth,
  width = MAX_VIEWPORT_SIZE
}) => {
  const imageWidth = Math.min(MAX_VIEWPORT_SIZE, imgWidth || width);
  const template = __DEVELOPMENT__
    ? `w:${imageWidth}/q:${imageQuality}`
    : `w:${imageWidth}`;
  // Doing the image's template URL transform

  const resizeTemplateUrl = imgUrl?.replace('{template}', template);

  return resizeTemplateUrl;
};

export const getProxiedImagesItem = (item, height) => {
  const { image } = item;
  if (!image) {
    return item;
  }
  const accedoControlProxiedImages = image.map(img => {
    const { fileUrl } = img;
    const proxiedControlImage = getProxiedControlImage(
      fileUrl,
      height,
      imageQuality
    );

    return {
      ...img,
      fileUrl: proxiedControlImage
    };
  });

  return {
    ...item,
    image: accedoControlProxiedImages
  };
};

/**
 * Function to get the proxied images.
 *
 * @param {Object[]} items Array of items object with the accedo control asset ids
 * @param {Number} height desired height for the images.
 * @returns {Object[]} Array of objets
 */
export const getProxiedImagesItems = (items, height) => {
  return items.map(item => getProxiedImagesItem(item, height));
};
