import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withFocus } from '@accedo/vdkweb-navigation';
import { getParameterizedImage } from '#/models/utils';
import Nav from '#/interfaces/Nav';
import { Collection } from '#/interfaces/Collection';
import useHistoryPush from '#/hooks/history/useHistoryPush';
import { ROUTES } from '#/config/constants';
import Avatar from '#/components/Avatar';
import styles from './characterCarouselItem.scss';

const BORDER_SPACE = 10;
const NAME_SPACE = 42;

type CharacterCarouselItemOrigType = {
  nav: Nav;
  data: Collection;
  width: string;
  height: string;
};

const CharacterCarouselItemOrig = ({
  nav,
  data,
  width,
  height
}: CharacterCarouselItemOrigType) => {
  const historyPush = useHistoryPush();
  return (
    <div
      className={styles.itemContainer}
      style={{
        width,
        height: height + (data.name ? NAME_SPACE : BORDER_SPACE)
      }}
    >
      <div
        className={`${styles.imageContainer} ${
          nav.isFocused ? styles.focusedContainer : ''
        }`}
        role="link"
        aria-label={data.name}
        onMouseOver={nav.onMouseOver}
        onMouseOut={nav.onMouseOut}
        onClick={() => {
          historyPush({
            path: `${ROUTES.collection}/${data.id}`,
            state: {
              asset: data
            }
          });
        }}
      >
        <Avatar
          src={getParameterizedImage({
            imgUrl: data.picture,
            imgWidth: 200,
            width: 200
          })}
          gradient={false}
          isFocused={nav.isFocused}
          name={data.name}
          size={200}
        />
      </div>
      <div className={styles.name}>{data.name && <span>{data.name}</span>}</div>
      <div className={styles.role}>{data.role && <span>{data.role}</span>}</div>
    </div>
  );
};

const CharacterCarouselItem = withFocus(CharacterCarouselItemOrig);

const componentProps = {
  data: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  nav: PropTypes.object
};

CharacterCarouselItemOrig.propTypes = componentProps;
CharacterCarouselItem.propTypes = componentProps;

const propsAreEqual = (
  prevProps: CharacterCarouselItemOrigType,
  nextProps: CharacterCarouselItemOrigType
) => {
  const { data: prevData, nav: prevNav } = prevProps || {};
  const { data: nextData, nav: nextNav } = nextProps || {};

  return (
    prevData.id === nextData.id &&
    prevData.name === nextData.name &&
    prevData.role === nextData.role &&
    prevNav.isFocused === nextNav.isFocused &&
    prevNav.nextleft === nextNav.nextleft &&
    prevNav.nextright === nextNav.nextright &&
    prevNav.nextdown === nextNav.nextdown &&
    prevNav.nextup === nextNav.nextup
  );
};

export default memo(CharacterCarouselItem, propsAreEqual);
